<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        v-if="chartData"
        cols="12"
      >
        <statistics
          v-if="dummyMaandProductie && chartData"
          :dummy-maand-productie="dummyMaandProductie"
          :chart-data="chartData"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        cols="12"
        md="6"
      >
        <radial-goal
          :data="goalDataProduction"
          :pos="true"
          title="Voortgang productie"
          :table-data="shortTableDataProduction"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <radial-goal
          :data="goalDataCost"
          :pos="false"
          title="Voortgang kosten"
          :table-data="shortTableDataCost"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <donut-chart
          :data="donutChartProduction"
          title="Productie"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <donut-chart
          :data="donutChartCost"
          title="Royementen"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import dashboardStoreModule from '@/views/pages/dashboard/dashboardStoreModule'
import DonutChart from '@/views/pages/dashboard/dashboard-component/DonutChart.vue'
import Statistics from '@/views/pages/dashboard/dashboard-component/Statistics.vue'
import RadialGoal from '@/views/pages/dashboard/dashboard-component/RadialGoal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    DonutChart,
    RadialGoal,
    Statistics,
  },
  data() {
    return {
      statisticData: {},
      // assets: ['TOR', 'BFS', 'APSR', 'FSP', 'SKV', 'TIR'],
      assets: ['TOR', 'BFS', 'APSR'],
      // costs: ['Agentenprovisie', 'Claims'],
      // costs: ['Agentenprovisie'],
      chartData: null,
      royementSeries: null,
      // chartData: {
      //   labels: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September'],
      //   datasets: [
      //     {
      //       label: 'Brutopremie geboekt',
      //       borderColor: '#fdb813',
      //       data: [8055953.50, 3688696.29, 5036271.19, -121774.54, 1080594.95, 866173.87, 3209396.35, 1026245.54, 2407240.94],
      //       pointBackgroundColor: '#fdb813',
      //       fill: false,
      //     },
      //   ],
      // },
      dummyMaandProductie: null,
      // dummyMaandProductie: [
      //   {
      //     month: 'Januari',
      //     bruto: 8055953.50,
      //     perEmployee: 1150850.50,
      //     ratioPerEmployee: 0.1429,
      //     prodPerDay: 402797.68,
      //     ratioProdPerDay: 0.05,
      //   }, {
      //     month: 'Februari',
      //     bruto: 3688696.29,
      //     perEmployee: 526956.61,
      //     ratioPerEmployee: 0.1429,
      //     prodPerDay: 184434.81,
      //     ratioProdPerDay: 0.05,
      //   }, {
      //     month: 'Maart',
      //     bruto: 5036271.19,
      //     perEmployee: 719467.31,
      //     ratioPerEmployee: 0.1429,
      //     prodPerDay: 239822.44,
      //     ratioProdPerDay: 0.0476,
      //   }, {
      //     month: 'April',
      //     bruto: -121774.54,
      //     perEmployee: -17396.36,
      //     ratioPerEmployee: 0.1429,
      //     prodPerDay: -6088.73,
      //     ratioProdPerDay: 0.05,
      //   }, {
      //     month: 'Mei',
      //     bruto: 1080594.95,
      //     perEmployee: 135074.37,
      //     ratioPerEmployee: 0.125,
      //     prodPerDay: 56873.42,
      //     ratioProdPerDay: 0.0526,
      //   }, {
      //     month: 'Juni',
      //     bruto: 866173.87,
      //     perEmployee: 108271.73,
      //     ratioPerEmployee: 0.125,
      //     prodPerDay: 39371.54,
      //     ratioProdPerDay: 0.0455,
      //   },
      //   {
      //     month: 'Juli',
      //     bruto: 3209396.35,
      //     perEmployee: 401174.54,
      //     ratioPerEmployee: 0.125,
      //     prodPerDay: 152828.4,
      //     ratioProdPerDay: 0.0476,
      //   },
      //   {
      //     month: 'Augustus',
      //     bruto: 1026245.54,
      //     perEmployee: 128280.69,
      //     ratioPerEmployee: 0.125,
      //     prodPerDay: 48868.84,
      //     ratioProdPerDay: 0.0476,
      //   },
      //   {
      //     month: 'September',
      //     bruto: 2407240.94,
      //     perEmployee: 300905.12,
      //     ratioPerEmployee: 0.125,
      //     prodPerDay: 109420.04,
      //     ratioProdPerDay: 0.0455,
      //   },
      // ],
      goalDataProduction: null,
      // goalDataProduction: {
      //   total: { now: 2407240.94, last: 209.43, goal: 155.51 },
      //   TOR: { now: 738386.54, last: 69.66, goal: 125.78 },
      //   BFS: { now: 80289.91, last: 410.03, goal: 179.40 },
      //   APSR: { now: 105925.11, last: 76.10, goal: 114.14 },
      //   FSP: { now: 47892.34, last: -708.85, goal: 97.31 },
      //   SKV: { now: -3999, last: 0, goal: 1880.26 },
      //   TIR: { now: 1039280.57, last: 0, goal: 100 },
      // },
      shortTableDataProduction: null,
      // shortTableDataProduction: [
      //   {
      //     name: 'TOR', now: 738386.54, last: 69.66, goal: 125.78,
      //   },
      //   {
      //     name: 'BFS', now: 80289.91, last: 410.03, goal: 179.40,
      //   },
      //   {
      //     name: 'APSR', now: 105925.11, last: 76.10, goal: 114.14,
      //   },
      //   {
      //     name: 'FSP', now: 47892.34, last: -708.85, goal: 97.31,
      //   },
      //   {
      //     name: 'SKV', now: -3999, last: 0, goal: 1880.26,
      //   },
      //   {
      //     name: 'TIR', now: 1039280.57, last: 0, goal: 100,
      //   },
      //   {
      //     name: 'Totaal', now: 2407240.94, last: 209.43, goal: 155.51,
      //   }],
      goalDataCost: null,
      // goalDataCost: {
      //   agentenProvision: { now: 50344.04, last: -28.19, goal: 53.19 },
      //   claims: { now: 23000, last: 0, goal: 40.00 },
      //   total: { now: 83344.04, last: 4.29, goal: 42.78 },
      // },
      shortTableDataCost: null,
      // shortTableDataCost: [
      //   {
      //     name: 'Agentenprovisie', now: 50344.04, last: -28.19, goal: 53.19,
      //   },
      //   {
      //     name: 'Claims', now: 23000, last: 0, goal: 40.00,
      //   },
      //   {
      //     name: 'Totaal', now: 83344.04, last: 4.29, goal: 42.78,
      //   }],
      donutChartProduction: {},
      donutChartCost: {},
    }
  },
  created() {
    this.fetchAllData()
  },
  methods: {
    fixDash2Data(data) {
      this.royementSeries = [data.TOR.royement.now, data.BFS.royement.now, data.APSR.royement.now]
    },
    fixDash4Data(data) {
      const months = []
      const lineChartData = []
      this.dummyMaandProductie = data
      data.forEach(i => {
        months.push(i.month)
        lineChartData.push(i.bruto)
      })
      this.chartData = {
        labels: months,
        datasets: [
          {
            label: 'Brutopremie geboekt',
            borderColor: '#fdb813',
            data: lineChartData,
            pointBackgroundColor: '#fdb813',
            fill: false,
          },
        ],
      }
    },
    fixDash5Data(data) {
      this.goalDataProduction = {
        total: {
          now: data.TOR.now + data.BFS.now + data.APSR.now,
          last: data.TOR.last + data.BFS.last + data.APSR.last,
          goal: data.TOR.last + data.BFS.last + data.APSR.last,
        },
        TOR: { now: data.TOR.now, last: data.TOR.last, goal: data.TOR.last },
        BFS: { now: data.BFS.now, last: data.BFS.last, goal: data.BFS.last },
        APSR: { now: data.APSR.now, last: data.APSR.last, goal: data.APSR.last },
      }
      this.shortTableDataProduction = [
        {
          name: 'TOR', now: data.TOR.now, last: data.TOR.last, goal: data.TOR.last,
        },
        {
          name: 'BFS', now: data.BFS.now, last: data.BFS.last, goal: data.BFS.last,
        },
        {
          name: 'APSR', now: data.APSR.now, last: data.APSR.last, goal: data.APSR.last,
        }]
    },
    fixDash6Data(data) {
      this.goalDataCost = {
        agentenProvision: { now: data.agentProvision.now, last: data.agentProvision.last, goal: data.agentProvision.last },
        total: { now: data.total.now, last: data.total.last, goal: data.total.last },
      }
      this.shortTableDataCost = [
        {
          name: 'Agentenprovisie', now: data.agentProvision.now, last: data.agentProvision.last, goal: data.agentProvision.last,
        },
        {
          name: 'Totaal', now: data.total.now, last: data.total.last, goal: data.total.last,
        }]
    },
    fetchAllData() {
      Promise.all([
        store.dispatch('app-dashboard/fetchDashboardFinancialOverview'),
      ])
        .then(async ([resOverviewData]) => {
          this.fixDash2Data(resOverviewData.data.dash2)
          this.fixDash4Data(resOverviewData.data.dash4)
          this.fixDash5Data(resOverviewData.data.dash5)
          this.fixDash6Data(resOverviewData.data.dash6)
          // this.fixStatisticData()
          this.fixDonut()
          // this.fixDash2Data(resOverviewData.data.dash2)
          // this.fixDash3Data(resOverviewData.data.dash3.total)
          // await this.fixLineData()
          // this.overviewInterval = setInterval(() => {
          //   this.fetchAllData()
          // }, 30000000000)
        })
        .catch(() => {
          // clearInterval(this.overviewInterval)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    fixDonut() {
      const series = []
      this.shortTableDataProduction.forEach(i => {
        if (this.assets.includes(i.name)) {
          series.push(i.now)
        }
      })
      this.donutChartProduction = {
        series,
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            '#ea5455',
            '#ffc107',
            '#00cfe8',
            '#b8c2cc',
            '#20c997',
            '#7367f0',
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                },
              },
            },
          },
          labels: this.assets,
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      }
      this.donutChartCost = {
        series: this.royementSeries,
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: [
            '#ea5455',
            '#ffc107',
            '#00cfe8',
            '#b8c2cc',
            '#20c997',
            '#7367f0',
          ],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                },
              },
            },
          },
          labels: this.assets,
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      }
    },
    // fixStatisticData() {
    //   let bruto = 0
    //   let perEmployee = 0
    //   let ratioPerEmployee = 0
    //   let ratioProdPerDay = 0
    //   let perEmployeeTotal = 0
    //   let brutoAvg = 0
    //   let prodPerDayAvg = 0
    //   this.dummyMaandProductie.forEach(i => {
    //     bruto += i.bruto
    //     brutoAvg += (i.bruto * (1 / this.dummyMaandProductie.length))
    //     perEmployee += (i.perEmployee * (1 / this.dummyMaandProductie.length))
    //     perEmployeeTotal += i.perEmployee
    //     prodPerDayAvg += (i.prodPerDay * (1 / this.dummyMaandProductie.length))
    //   })
    //   ratioPerEmployee = ((perEmployeeTotal / bruto) * 100).toFixed(2)
    //   ratioProdPerDay = ((prodPerDayAvg / brutoAvg) * 100).toFixed(2)
    //   this.statisticData = {
    //     bruto, perEmployee, ratioPerEmployee, prodPerDayAvg, ratioProdPerDay,
    //   }
    // },
  },
  setup() {
    const DASHBOARD_APP_STORE_MODULE_NAME = 'app-dashboard'

    // Register module
    if (!store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) store.registerModule(DASHBOARD_APP_STORE_MODULE_NAME, dashboardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DASHBOARD_APP_STORE_MODULE_NAME)) store.unregisterModule(DASHBOARD_APP_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.echarts{
  width: 100% !important;
}
</style>
