<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <h4 class="mb-0">
        {{ title }}<br>
        <small>Realisatie t.o.v vorig jaar</small>
      </h4>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          :id="`order-view-action-row-${title}`"
          class="cursor-pointer mx-1"
          icon="HelpCircleIcon"
          size="25"
        />
        <b-tooltip
          :target="`order-view-action-row-${title}`"
          placement="left"
        >
          <p class="mb-0">
            Overzicht van de {{ title }},
            de toe/afname in vergelijking met vorig jaar
          </p>
        </b-tooltip>
      </b-card-text>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="goalOverviewRadialBar"
      :series="[((data.total.now/data.total.goal)*100).toFixed(2)]"
    />
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-row colspan="2">
          <b-col
            cols="12"
            class="mb-2"
          >
            <h1 class="font-small-3 font-weight-bolder mb-0">
              Vorig jaar
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <h4 class="mb-0">
              SRD {{ data.total.last ? kFormatter(data.total.last) : "0" }}
            </h4>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-row colspan="2">
          <b-col
            cols="12"
            class="mb-2"
          >
            <h1 class="font-small-3 font-weight-bolder mb-0">
              Gerealiseerd
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <div v-if="data.total.goal>=100 && pos">
              <h4
                class="mb-0"
                style="color:#378805"
              >
                SRD {{ data.total.now ? kFormatter(data.total.now) : "0" }}
              </h4>
            </div>
            <div v-if="data.total.goal<100 && pos">
              <h4
                class="mb-0"
                style="color:#FF0000"
              >
                SRD {{ data.total.now ? kFormatter(data.total.now) : "0" }}
              </h4>
            </div>
            <div v-if="data.total.goal>=100 && !pos">
              <h4
                class="mb-0"
                style="color:#FF0000"
              >
                SRD {{ data.total.now ? kFormatter(data.total.now) : "0" }}
              </h4>
            </div>
            <div v-if="data.total.goal<100 && !pos">
              <h4
                class="mb-0"
                style="color:#378805"
              >
                SRD {{ data.total.now ? kFormatter(data.total.now) : "0" }}
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col>
        <short-overview-table
          :table-data="tableData"
          :pos="pos"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { kFormatter } from '@core/utils/filter'
import ShortOverviewTable from '@/views/pages/dashboard/dashboard-component/ShortOverviewTable.vue'

const { BTooltip } = require('bootstrap-vue')

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    // BImg,
    ShortOverviewTable,
    BTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: () => null,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    pos: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      totalWebPrev: null,
      totalWebNow: null,
      totalShopPrev: null,
      totalShopNow: null,
      pct: null,
      goalOverviewRadialBar: {},
    }
  },
  created() {
    this.getRadial()
  },
  methods: {
    getRadial() {
      if (this.pos) {
        this.goalOverviewRadialBar = {
          chart: {
            height: 245,
            type: 'radialBar',
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: [$goalStrokeColor2],
          plotOptions: {
            radialBar: {
              type: 'radialBar',
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: $strokeColor,
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: $textHeadingColor,
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.danger, $themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        }
      } else {
        this.goalOverviewRadialBar = {
          chart: {
            height: 245,
            type: 'radialBar',
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: [$goalStrokeColor2],
          plotOptions: {
            radialBar: {
              type: 'radialBar',
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: $strokeColor,
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: $textHeadingColor,
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success, $themeColors.danger],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        }
      }
    },
    kFormatter,
  },
}
</script>
