<template>
  <b-card
    v-if="statisticData"
    no-body
    class="card-statistics"
  >
    <b-row>
      <b-card-header>
        <b-col
          cols="12"
          md="12"
        >
          <b-card-title>
            Productie incl. royementen
          </b-card-title>
        </b-col>
      </b-card-header>
    </b-row>
    <b-row
      class="statistics-body match-height"
      style="margin-top: 0 !important;;padding-top: 0 !important;"
    >
      <b-col>
        <b-card-text>
          <b-form-group label="Maand filter:">
            <b-form-checkbox-group
              v-model="filterData"
              name="month-filters"
            >
              <b-row>
                <b-col
                  v-for="(label, index) in filters"
                  :key="index"
                  cols="12"
                  md="2"
                  p="1"
                >
                  <b-form-checkbox :value="label">
                    {{ label }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-checkbox-group>
          </b-form-group>
        </b-card-text>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <line-chart-component
          v-if="chartData"
          :key="componentKey"
          :data="chartData"
        />
      </b-col>
      <b-col
        cols="12"
        md="12"
        class="mt-2"
      >
        <b-row class="mb-3 text-center">
          <b-col>
            <h5 class="font-weight-bolder mb-0">
              SRD {{ statisticData.bruto ? kFormatter(statisticData.bruto) : "0" }}
            </h5>
            <b-card-text class="font-small-3 mb-0">
              Brutopremie geboekt
            </b-card-text>
          </b-col>
          <b-col>

            <h5 class="font-weight-bolder mb-0">
              SRD {{ statisticData.perEmployee ? kFormatter(statisticData.perEmployee) : "0" }}
            </h5>
            <b-card-text class="font-small-3 mb-0">
              Productiviteit per medewerker per maand
            </b-card-text>
          </b-col>
          <b-col>
            <h5 class="font-weight-bolder mb-0">
              {{ statisticData.ratioPerEmployee ? statisticData.ratioPerEmployee : "0" }}%
            </h5>
            <b-card-text class="font-small-3 mb-0">
              Ratio per medewerker
            </b-card-text>
          </b-col>
          <b-col>
            <h5 class="font-weight-bolder mb-0">
              SRD {{ statisticData.prodPerDayAvg ? kFormatter(statisticData.prodPerDayAvg) : "0" }}
            </h5>
            <b-card-text class="font-small-3 mb-0">
              Productiviteit per dag
            </b-card-text>
          </b-col>
          <b-col>
            <h5 class="font-weight-bolder mb-0">
              {{ statisticData.ratioProdPerDay ? statisticData.ratioProdPerDay : "0" }}%
            </h5>
            <b-card-text class="font-small-3 mb-0">
              Productiviteitsratio per dag
            </b-card-text>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BRow, BCol, BFormGroup, BFormCheckboxGroup, BFormCheckbox,
  // BImg,
  // BTooltip,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import LineChartComponent from '@/views/pages/dashboard/dashboard-component/LineChartComponent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    LineChartComponent,
    BFormCheckboxGroup,
    BFormGroup,
    BFormCheckbox,
    // BImg,
    // BTooltip,
  },
  props: {
    dummyMaandProductie: {
      type: Array,
      default: () => {},
    },
    chartData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      radio: null,
      filterData: [],
      filters: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      statisticData: {},
      clonedData: {},
      componentKey: 0,
    }
  },
  watch: {
    filterData: {
      handler() {
        this.fixStatisticData()
        this.fixChartData()
      },
    },
  },
  created() {
    this.chartDataOriginal = JSON.parse(JSON.stringify(this.chartData))
    this.filterData = JSON.parse(JSON.stringify(this.filters))
  },
  methods: {
    kFormatter,
    fixStatisticData() {
      let bruto = 0
      let perEmployee = 0
      let ratioPerEmployee = 0
      let ratioProdPerDay = 0
      let perEmployeeTotal = 0
      let brutoAvg = 0
      let prodPerDayAvg = 0
      if (this.dummyMaandProductie) {
        this.dummyMaandProductie.forEach(i => {
          if (this.filterData.includes(i.month)) {
            bruto += i.bruto
            brutoAvg += (i.bruto * (1 / this.dummyMaandProductie.length))
            perEmployee += (i.perEmployee * (1 / this.dummyMaandProductie.length))
            perEmployeeTotal += i.perEmployee
            prodPerDayAvg += (i.prodPerDay * (1 / this.dummyMaandProductie.length))
          }
        })
      }
      if (bruto > 0) {
        ratioPerEmployee = ((perEmployeeTotal / bruto) * 100).toFixed(2)
      }
      if (brutoAvg > 0) {
        ratioProdPerDay = ((prodPerDayAvg / brutoAvg) * 100).toFixed(2)
      }
      this.statisticData = {
        bruto, perEmployee, ratioPerEmployee, prodPerDayAvg, ratioProdPerDay,
      }
    },
    fixChartData() {
      const labels = []
      const data = []
      this.chartDataOriginal.labels.forEach((value, index) => {
        if (this.filterData.includes(value)) {
          labels.push(value)
          data.push(this.chartDataOriginal.datasets[0].data[index])
        }
      })
      this.chartData.labels = labels
      this.chartData.datasets[0].data = data
      this.refreshChart()
    },
    refreshChart() {
      this.componentKey += 1
    },
  },
}
</script>
