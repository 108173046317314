<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- company -->
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.name }}
            </div>
          </div>
        </div>
      </template>
      <!-- revenue -->
      <template #cell(now)="data">
        <strong>SRD {{ data.item.now ? kFormatter(data.item.now) : "0" }}</strong>
      </template>
      <template #cell(last)="data">
        <div
          v-if="data.item.last && data.item.now - data.item.last >= 0 && pos"
        >
          <strong style="color:#378805">{{ data.item.last ? ((data.item.now - data.item.last)/data.item.last).toFixed(2) : "0" }}%</strong>
        </div>
        <div
          v-if="data.item.last && data.item.now - data.item.last < 0 && pos"
        >
          <strong style="color:#FF0000">{{ data.item.last ? ((data.item.now - data.item.last)/data.item.last).toFixed(2) : "0" }}%</strong>
        </div>
        <div
          v-if="data.item.last && data.item.now - data.item.last >= 0 && !pos"
        >
          <strong style="color:#FF0000">{{ data.item.last ? ((data.item.now - data.item.last)/data.item.last).toFixed(2) : "0" }}%</strong>
        </div>
        <div
          v-if="data.item.last && data.item.now - data.item.last < 0 && !pos"
        >
          <strong style="color:#378805">{{ data.item.last ? ((data.item.now - data.item.last)/data.item.last).toFixed(2) : "0" }}%</strong>
        </div>
      </template>
<!--      <template #cell(goal)="data">-->
<!--        <div-->
<!--          v-if="data.item.goal && data.item.goal >= 100 && pos"-->
<!--        >-->
<!--          <strong style="color:#378805">{{ data.item.goal ? data.item.goal : "0" }}%</strong>-->
<!--        </div>-->
<!--        <div-->
<!--          v-if="data.item.goal && data.item.goal < 100 && pos"-->
<!--        >-->
<!--          <strong style="color:#FF0000">{{ data.item.goal ? data.item.goal : "0" }}%</strong>-->
<!--        </div>-->
<!--        <div-->
<!--          v-if="data.item.goal && data.item.goal >= 100 && !pos"-->
<!--        >-->
<!--          <strong style="color:#FF0000">{{ data.item.goal ? data.item.goal : "0" }}%</strong>-->
<!--        </div>-->
<!--        <div-->
<!--          v-if="data.item.goal && data.item.goal < 100 && !pos"-->
<!--        >-->
<!--          <strong style="color:#378805">{{ data.item.goal ? data.item.goal : "0" }}%</strong>-->
<!--        </div>-->
<!--      </template>-->
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    pos: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'name', label: '' },
        { key: 'now', label: 'Deze maand' },
        { key: 'last', label: 'toe/afname vorig jaar' },
      ],
    }
  },
  methods: {
    kFormatter,
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
