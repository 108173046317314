<template>
  <div
    v-if="data"
    no-body
    class="card-statistics"
  >
    <h4 class="mb-0">
      {{ title }}
    </h4>
    <line-chart :data="data" />
  </div>
</template>

<script>
import LineChart from './LineChart.vue'

export default {
  components: {
    LineChart,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: () => null,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
