<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: 20,
        },
        legend: {
          position: 'top',
        },
        // scales: {
        //   yAxes: [{
        //     id: 'A',
        //     type: 'linear',
        //     position: 'left',
        //   }, {
        //     id: 'B',
        //     type: 'linear',
        //     position: 'right',
        //   }],
        // },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'SRD',
            },
          }],
        },
      },
    }
  },
  mounted() {
    this.renderChart(this.data, this.options)
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
